import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import event1 from '../../assets/images/events/event1.jpg'
import event2 from '../../assets/images/events/event2.jpg'
import event3 from '../../assets/images/events/event3.jpg'
import event4 from '../../assets/images/events/event4.jpg'
import event5 from '../../assets/images/events/event5.jpg'
import event6 from '../../assets/images/events/event6.jpg'
import event7 from '../../assets/images/events/event7.jpg'
import event8 from '../../assets/images/events/event8.jpg'
import event9 from '../../assets/images/events/event9.jpg'

const EventsCard = () => {
    return (
        <div className="events-area pt-100 pb-70">
            <div className="container">

                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="project" /> Recent Projects
                    </span>
                    <h2>Check Some Of Our Recent Work</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event1} alt="event" />
                                </Link>
                                <span className="date">Wed, 20 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                        3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> Pleasanton
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event2} alt="event" />
                                </Link>
                                <span className="date">Tue, 19 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> San Ramon
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event3} alt="event" />
                                </Link>
                                <span className="date">Mon, 18 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> San Jose
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event4} alt="event" />
                                </Link>
                                <span className="date">Sun, 17 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>

                                <span className="location">
                                    <i className="bx bx-map"></i> Fremont
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event5} alt="event" />
                                </Link>
                                <span className="date">Sat, 16 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> Redwood City
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event6} alt="event" />
                                </Link>
                                <span className="date">Fri, 15 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> Danville
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event7} alt="event" />
                                </Link>
                                <span className="date">Thu, 14 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> Milpitas
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event8} alt="event" />
                                </Link>
                                <span className="date">Wed, 13 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> Pleasanton
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-events-box">
                            <div className="image">
                                <Link to="/project-details" className="d-block">
                                    <img src={event9} alt="event" />
                                </Link>
                                <span className="date">Tue, 12 May, 2020</span>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/project-details">
                                    3 Beds 2.5 Bath Singel Family
                                    </Link>
                                </h3>
                                <span className="location">
                                    <i className="bx bx-map"></i> Sunnyvale
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventsCard