import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import EventsCard from '../components/Events/EventsCard'

const Events = () => {
    return (
        <Layout>
            <NavbarStaging />
            <EventsCard />
            <Footer />
        </Layout>
    );
}

export default Events